.App {
  text-align: center;
  background-color: #0e0e10;
  min-height: 100vh;
}

.search-container {
  background: #282c34;
  padding: 20px;
  display: flex;
}

.search-controls-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-controls {
  width: 100%;
  display: flex;
}

.search-field {
  width: 90%;
}

.custom-search {
  text-align: left;
  color: #BEBEBE;
  margin: 30px 30px 50px 30px;
  font-size: 22px;
}

.results {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  min-height: calc(100vh);
}

.result {
  width: 23%;
  margin-right: 20px;
  text-align: left;
  margin-bottom: 30px;
}

.thumbnail {
  width: 100%;
}

.thumbnail-container:hover {
  background: #30C5FF;
  padding: 0px 0px 2px 6px;
}

.title {
  color: white;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 6px 0 2px 0;
}

.channelName {
  color: #BEBEBE;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.video-link {
  text-decoration: none;
}

.game-logo-container {
  text-align: left;
  padding: 24px;
  display: flex;
}

.game-details {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px 30px;
}

.game-details-name {
  font-size: 38px;
  font-weight: 700;
  margin: 0;
}

.game-details-channel-count {
  font-size: 20px;
  font-size: 20px;
  margin: 4px 0;
}

.game-details-description {
  font-size: 14px;
  color: #BEBEBE;
  max-width: 750px;
}

.sorted-by {
  text-align: left;
  color: #BEBEBE;
  padding-left: 30px;
  font-size: 16px;
}

.games-container {
  display: flex;
  width: 70%;
}

.quick-search-game-container {
  cursor: pointer;
  margin: 0 8px;
}

.quick-search-game {
  height: 120px
}

.made-by {
  color: #BEBEBE;
  text-align: right;
  padding: 10px 20px;
}

.made-by-link {
  color: lightblue;
  text-decoration: none;
}

.search-button {
  height: 58px;
}

.made-by-icon {
  margin: -2px 3px;
}

.iframe {
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 1100px) {
  .result {
    width: 31%;
  }
}

@media only screen and (max-width: 800px) {
  .result {
    width: 47%;
  }
}

@media only screen and (max-width: 600px) {
  .result {
    width: 100%;
  }

  .game-logo-container {
    flex-direction: column;
  }

  .game-details {
    padding: 20px 0;
  }

  .sorted-by {
    padding-left: 20px;
  }

  .search-container {
    flex-direction: column;
  }

  .search-controls-container {
    width: 100%;
  }

  .games-container {
    width: 100%;
    justify-content: center;
    margin-bottom: 30px;
  }
}